/**
 * menu-accordion
 */

export function menu_accordion() {

  document.addEventListener("DOMContentLoaded", function() {
    const menuItems = document.querySelectorAll(".sidebar-search__menu > .menu > li.menu-item-has-children");

    menuItems.forEach(function(menuItem) {
      const toggleBtn = menuItem.querySelector("span");
      console.log({toggleBtn});
      const subMenu = menuItem.querySelector(".sub-menu");

      toggleBtn.addEventListener("click", function() {
        menuItem.classList.toggle("active");
        subMenu.classList.toggle("slideToggle");
      });
    });
  });

}
