/**
 * mw wp form scroll fix
 */

import "jquery";
export function mw_scroll_fix() {

jQuery(function($) {
  let headerheight;
  let navheight;

  if ( $('.error')[0] ) {
    headerheight = document.getElementById('site-header').clientHeight;
    navheight = document.getElementById('p-header-global-nav').clientHeight;

    $('.mw_wp_form').addClass('mw_wp_form_error');
    var errorEl = $('.mw_wp_form').eq(0);

    if (window.matchMedia('(max-width: 864px)').matches) {
      var position = errorEl.offset().top - headerheight - 70;
    } else if (window.matchMedia('(min-width: 864px)').matches) {
      var position = errorEl.offset().top - navheight - 100;
    }
    $('body,html').animate({scrollTop:position}, 100, 'linear');
  }

  if ( $('.mw_wp_form_confirm').length ) {
    headerheight = document.getElementById('site-header').clientHeight;
    navheight = document.getElementById('p-header-global-nav').clientHeight;
    if (window.matchMedia('(max-width: 864px)').matches) {
      var position = $('.mw_wp_form_confirm').offset().top - headerheight - 70;
    } else if (window.matchMedia('(min-width: 864px)').matches) {
      var position = $('.mw_wp_form_confirm').offset().top - navheight - 100;
    }
    $('body,html').animate({scrollTop:position}, 100, 'linear');
  }

  if ( $('.mw_wp_form_complete').length ) {
    headerheight = document.getElementById('site-header').clientHeight;
    navheight = document.getElementById('p-header-global-nav').clientHeight;
    if (window.matchMedia('(max-width: 864px)').matches) {
      var position = $('.mw_wp_form_complete').offset().top - headerheight - 200;
    } else if (window.matchMedia('(min-width: 864px)').matches) {
      var position = $('.mw_wp_form_complete').offset().top - navheight - 300;
    }
    $('body,html').animate({scrollTop:position}, 100, 'linear');
  }

});

}
