import "@babel/polyfill";
import { humburger_menu } from "./modules/humburger-menu";
import { anchor_link } from "./modules/anchor-link";
import { rwd_image_maps } from "./modules/rwd-image-maps";
import { window_size } from "./modules/theme";
import { fitSticky } from "./modules/sticky";
import { menu_accordion } from "./modules/menu-accordion";
import { mw_scroll_fix } from "./modules/mw-scroll-fix";
import { viewport_less_than_360 } from "./modules/viewport";

// window size write to html tag
window_size();

// viewport less than 360px
viewport_less_than_360();

// humburger menu
humburger_menu();

// anchor link fix
anchor_link();

// rwd_image_maps
rwd_image_maps();

// stiky
fitSticky();

// menu accordion
menu_accordion();

// mw wp form scroll fix
mw_scroll_fix();
