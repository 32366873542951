/**
 * humburger
 */
export function humburger_menu() {
  const hamButton = document.getElementById('hamburger-button');
  const humBody   = document.getElementById('body');
  const hamDrawer = document.getElementById('drawer');

  hamButton.addEventListener('click', function() {
    hamButton.classList.toggle('is-open');
    humBody.classList.toggle('is-locked');

    if ( hamDrawer.getAttribute('aria-expanded') == 'false' ) {
      hamDrawer.setAttribute('aria-expanded', true);
    } else {
      hamDrawer.setAttribute('aria-expanded', false);
    }
    if ( hamDrawer.getAttribute('aria-expanded') == 'true' ) {
      hamDrawer.setAttribute('aria-hidden', false);
    } else {
      hamDrawer.setAttribute('aria-hidden', true);
    }

    // let headerHeight = document.getElementById('site-header').clientHeight + 5;
    // document.getElementById('drawer-header').style.height = headerHeight + 'px';
  }, false);

  // window.addEventListener('resize', function() {
  //   let headerHeightResize = document.getElementById('site-header').clientHeight + 5;
  //   document.getElementById('drawer-header').style.height = headerHeightResize + 'px';
  // });

  /**
   * ドロワーメニュー内のアンカーリンク#を押した時に閉じる
   * drawer > .drawer-menu > li > a:href #
   */
  const humDrawerLink = document.querySelector('.drawer-menu a[href^="/#"]');
  if (!humDrawerLink){
    return false;
  }

  humDrawerLink.addEventListener('click', function() {
    hamButton.classList.remove('is-open');
    humBody.classList.remove('is-locked');

    if ( hamDrawer.getAttribute('aria-expanded') == 'false' ) {
      hamDrawer.setAttribute('aria-expanded', true);
    } else {
      hamDrawer.setAttribute('aria-expanded', false);
    }
    if ( hamDrawer.getAttribute('aria-expanded') == 'true' ) {
      hamDrawer.setAttribute('aria-hidden', false);
    } else {
      hamDrawer.setAttribute('aria-hidden', true);
    }

  }, false);
}
