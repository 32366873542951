/**
 * anchor link
 */
export function anchor_link() {
  const Ease = {
    easeInOut: t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1
  }
  const duration = 250;

  // headerHeight = document.getElementById('site-header').clientHeight;
  if (window.matchMedia('(max-width: 767px)').matches) {
    var headerHeight = 70;
  } else if (window.matchMedia('(min-width:768px)').matches) {
    var headerHeight = 120;
  }
  // console.log(headerHeight);

  // 同一ページからアンカーリンク移動時

  // window.addEventListener('DOMContentLoaded', () => {
  //   var smoothScrollTriggers = document.querySelectorAll('a[href*="#"]');
  //   var smoothScrollTriggersAttr = Array.prototype.slice.call(smoothScrollTriggers);
  //   smoothScrollTriggersAttr.forEach(function (smoothScrollTrigger) {
  //     smoothScrollTrigger.addEventListener('click', function (e) {
  //       var href = smoothScrollTrigger.getAttribute('href');
  //       var currentPostion = document.documentElement.scrollTop || document.body.scrollTop;
  //       var targetElement = document.getElementById(href.replace('#', ''));
  //       // console.log(targetElement);
  //       if (targetElement) {
  //         e.preventDefault();
  //         e.stopPropagation();
  //         var targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - headerHeight;
  //         var startTime = performance.now();
  //         var loop = function (nowTime) {
  //           var time = nowTime - startTime;
  //           var normalizedTime = time / duration;
  //           if (normalizedTime < 1) {
  //             window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * Ease.easeInOut(normalizedTime)));
  //             requestAnimationFrame(loop);
  //           } else {
  //             window.scrollTo(0, targetPosition);
  //           }
  //         }
  //         requestAnimationFrame(loop);
  //       }
  //     });
  //   });
  // });

  // 別ページからアンカーリンク移動時
  // document.addEventListener("DOMContentLoaded", () => {
  //   if (location.hash) {
  //     setTimeout(() => {
  //       window.scrollBy(0, - headerHeight);
  //     },100)
  //   }
  // });
}
